<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.routeParam.edc_login_id }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
            <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.merchant_edc_id" readonly label="EDC ID" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.merchant_name" readonly label="Merchant Name" outlined></v-text-field>
                                <v-text-field v-model="form.outlet_name" readonly label="Outlet Name" outlined></v-text-field>
                                <v-text-field v-model="form.prev_login_date" readonly label="Previous Login Date" outlined></v-text-field>
                                <v-text-field v-model="form.curr_login_date" readonly label="Current Login Date" outlined></v-text-field>
                                <v-textarea outlined v-model="form.edc_token_id" readonly label="Token Id"></v-textarea>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

           <v-col cols="12" md="12">
                <v-toolbar dark  color="panel2"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.edc_login_id" readonly label="EDC Login Id" outlined></v-text-field>                                
                                <v-text-field v-model="form.edc_password" label="Password" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.serial_number" label="Serial Number" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.pin" label="PIN" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.help_support_no" label="Help Support Contact" outlined></v-text-field>
                                <v-text-field v-model="form.sub_location" label="Location" outlined></v-text-field>
                                <v-select
                                    v-model="mUserLoginIdList"
                                    :items="mUserList"
                                    item-text="full_name"
                                    item-value="user_login_id"
                                    label="User Name"
                                    multiple
                                    chips
                                    persistent-hint
                                    outlined
                                    ></v-select>                                
                                <v-select
                                    v-model="mEgList"
                                    :items="egList"
                                    item-text="eg_name"
                                    item-value="earned_group_id"
                                    label="Earned Code"
                                    multiple
                                    chips
                                    persistent-hint
                                    outlined
                                    ></v-select>                                

                                <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.start_date"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                             
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false" outlined></v-date-picker>
                                </v-menu>
                                <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.end_date"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false" outlined></v-date-picker>
                                </v-menu>

                                <v-select
                                    v-model="form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                     outlined
                                ></v-select>

                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>
                
                <v-toolbar dark color="panel3"><v-toolbar-title>Receipt Template</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-form ref="mainForm">
                        <v-container class="py-0">
                            <v-row>
                                <v-col cols="12" md="12">
                    <v-select v-for="(rtType,index) in rtTypeList" :key="'rtindex'+index"
                        v-model="form.receipt_settings[rtType.code]"
                        :items="rtList[rtType.code]"
                        item-text="rt_name"
                        item-value="edc_rcpt_template_id"
                        :label="rtType.description"
                        standard
                        outlined

                    >

                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>

                </v-card>

            </v-col>
            

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApMerchantService from "@/services/ApMerchantService"
import ApParameterService from "@/services/ApParameterService"
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Merchants',disabled: false,href:'/merchants/',},
              {text:'EDC',disabled: false,href:'/merchants/',},
              {text:'Details',disabled: false,href:'#',},
              ],

            outlet_type_list:[],
            status_list:[],
            state_list:[],
            routeParam:[],
            mUserLoginIdList:[],
            mUserList:[],
            mEgList:[],
            egList:[],
            rtTypeList:[],
            rtList:[],
            form:{login_id:null,merchant_outlet_id:0,
            receipt_settings:{tx:0}},

            logo:'',
            encMerchantName:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
    this.routeParam['merchant_id'] = this.$route.params.merchant_id;
    this.routeParam['merchant_name'] = atob(this.$route.params.enc_merchant_name);
    this.routeParam['edc_id'] = this.$route.params.edc_id;
    this.routeParam['edc_login_id'] = this.$route.params.edc_login_id;
    // this.getParameter();
    this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        this.form.merchant_edc_id = this.routeParam.edc_id
        //console.log("merchantid",this.form.merchant_edc_id);
        try{
            ApMerchantService.view_merchant_edc(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }
                
                if(this.form.receipt_settings.length == 0){
                    this.form.receipt_settings = {"tx":"0"};
                }
                
                // console.log("receipt_settings",this.form.receipt_settings);
                
                this.status_list = res.data.statusList; 
                this.status_list = res.data.statusList; 
                this.mUserLoginIdList = res.data.mUserLoginIdList; 
                this.mEgList = res.data.mEgList;
                this.getParameter();
                //console.log('receipt_settings',this.form.receipt_settings);

                
            }).catch((e)=>{
                //console.log(e.response);
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','View EDC',e);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        let title = 'Update EDC'
        this.form['user_login_id'] = this.mUserLoginIdList;
        this.form['earned_group_id'] = this.mEgList;
        // console.log("receipt_settings",this.form.receipt_settings);
        try{
            ApMerchantService.update_merchant_edc(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success.');
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
            ;
        } catch(e) {
            if(e){
              this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');  
            }
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
        async getParameter(){
            try{
                // let parameter = {login_id:"",param_type:"add-edc",merchant_id:this.routeParam['merchant_id']}
                let parameter = {login_id:"",param_type:"add-edc",merchant_id:this.form.merchant_id}
                ApParameterService.get_parameter(parameter).then((res) => {
                    this.mUserList = res.data.mUserList;
                    this.egList = res.data.egList;
                    this.rtList = res.data.rtList;
                    this.rtTypeList = res.data.rtTypeList;
                    console.log("rtTypeList",this.rtTypeList);
                    //console.log(res.data);
                    
                }).catch((e)=>{
                    //console.log(e.response);
                    if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e);
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e);
            }
        },

  }
}
</script>